import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
// import Offering from '../../views/app/Offering';    // new
// import { Link } from 'react-router-dom';

console.log(process.env);
const Navbar = (props) => {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false); // as in constructor
    const [isAdmin, setIsAdmin] = useState(false);
    const [username, setUsername] = useState('');
    const [searchText, setSearchText] = useState('');
    const [showSearchBar, setShowSearchBar] = useState(false);

	useEffect(() => {
    	console.log("Props - ", props);
	}, [props]);

    // useEffect with an empty array ([]) as the second argument: componentDidMount
    useEffect(() => {
        // componentDidMount
        console.log(window.location);
        if (localStorage.getItem('token') !== null) {
            async function fetchUsername(token) {
                const res = await axios.post(process.env.REACT_APP_API_SERVER_URL+'/auth/verify', 
                {
                    token
                });
                if (res.data.username.length > 0) {
                    setUsername(res.data.username);
                    props.setUsername(res.data.username);
                    if (res.data.passcode.length > 0) {
                        props.setPasscode(res.data.passcode);
                    }
                    if (res.data.groups.length > 0) {
                        // setUserGroups(res.data.groups);
                        if (res.data.groups.includes("admin")) {
                            console.log("Admin user group.");
                            setIsAdmin(true);
                        }
                    }
                    setIsAuth(true);
                    props.setIsAuth(true);
                    if (res.data.devices.length > 0) {
                        console.log(res.data.devices);
                        props.setDevices(res.data.devices);
                    }
                }
                else {
                    setIsAuth(false);
                    props.setIsAuth(false);
                }
                setLoading(false);
            }
            fetchUsername(localStorage.getItem('token'));
        }
        else {
            setIsAuth(false);
            props.setIsAuth(false);
            setLoading(false);
        }
        return () => {
            // componentWillUnmount
            // console.log("componentWillUnmount");
        };
    }, []);

    const onPartialSubmit = (e) => {
        e.preventDefault();
        setSearchText(e.target.value);
        props.onNavbarSearchSubmit(e.target.value, false);
    };

    const onSubmit = e => {
        e.preventDefault();
        props.onNavbarSearchSubmit(searchText, true);
        setSearchText('');
    };

    const onCreate = e => {
        e.preventDefault();
        props.onNavbarCreate();
    };

    const handleLogout = e => {
        // console.log("handleLogout");
        e.preventDefault();
        localStorage.clear();
        setIsAuth(false);
        props.setIsAuth(false);
        window.location.replace(process.env.REACT_APP_SERVER_URL+'/login');
    };

    return (
        <div className="row flex-shrink-0" id="header_element">
            <div className="col-12">
            {/* HEADER PART - BEGIN */}
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-sm navbar-light bg-faded">
                        <button 
                            type="button" 
                            className="navbar-toggler" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#nav-content" 
                            aria-controls="nav-content" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    
                        {/* <!-- Brand --> */}
                        <a className="navbar-brand" href="/"><strong>DYNC</strong></a>
                        {/* <a className="navbar-brand" href="/"><strong>COME</strong></a> */}
                        {/* <a className="navbar-brand" href="/">DYNC</a> */}
                        
                        {/* <!-- Links --> */}
                        <div className="collapse navbar-collapse" id="nav-content">   
                            {/* <ul className="navbar-nav"> */}
                            {loading === false && (
                                <Fragment>
                                    {isAuth === true ? (
                                        <Fragment>
                                            {' '}
                                            <ul className="navbar-nav">
                                                {/* <li className="nav-item">
                                                    <a className="nav-link" href="/media">Media</a>
                                                </li> */}
                                                <li className="nav-item">
                                                    <a className="nav-link active" href="/screen">Screen</a>
                                                </li>
                                                {/* <li className="nav-item">
                                                    <a className="nav-link" href="/promises">Promise</a>
                                                </li> */}
                                                {/* <li className="nav-item">
                                                    <a className="nav-link" id="navlink-members" href="/members">Members</a>
                                                </li> */}
                                                {/* <li className="nav-item">
                                                    <a className="nav-link" id="navlink-offering" href="/offering">Offering</a>
                                                </li> */}
                                            </ul>
                                            {isAdmin && (
                                                <ul className="navbar-nav">
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="navlink-media" href="/media">Media</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="navlink-members" href="/members">Members</a>
                                                    </li>
                                                </ul>
                                            )}
                                            {showSearchBar && (
                                                <ul className="navbar-nav ms-auto">
                                                    <form className="d-flex" onSubmit={onSubmit}>
                                                        <input 
                                                            className="form-control me-2" 
                                                            id="navbar_search_input"
                                                            type="search" 
                                                            placeholder="Search"
                                                            aria-label="Search" 
                                                            // onChange={e => setSearchText(e.target.value)}
                                                            onChange={e => onPartialSubmit(e)}
                                                        />
                                                        {/* <button className="btn btn-outline-success me-2" type="submit">Search</button> */}
                                                        <button className="btn btn-outline-success me-2" type="submit">
                                                            <i className="bi bi-search"></i>
                                                        </button>
                                                    </form>
                                                    <form className="d-flex" onSubmit={onCreate}>
                                                        <button className="btn btn-outline-primary" type="submit">
                                                            <i id="navbar-create-icon" className="bi bi-plus-lg"></i>
                                                            {/* <i className="bi bi-person-plus"></i> */}
                                                        </button>
                                                    </form>
                                                </ul>
                                            )}
                                            <ul className="navbar-nav ms-auto">
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle" href="/dashboard" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {/* <strong>Account</strong> */}
                                                        {username}
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                                        <li><a className="dropdown-item" href="/change-password">Change Password</a></li>
                                                        <li><hr className="dropdown-divider"></hr></li>
                                                        <li><a className="dropdown-item" data-bs-toggle="modal" href="#staticBackdrop">Logout</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {' '}
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/login">Login</a>
                                                </li>
                                                {/* <li className="nav-item">
                                                    <a className="nav-link" href="/signup">Sign Up</a>
                                                </li> */}
                                            </ul>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </div>
                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">CONFIRMATION!</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Are you sure you want to logout?
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary" onClick={handleLogout}>Logout</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            {/* HEADER PART - END */}
            </div>
        </div>
    );
};

export default Navbar;